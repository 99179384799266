import styled from '@emotion/styled';
import { Fonts, mediaQuery, Spacings } from '@styles/index';
import Image from '@base/Image';
import useWindowSize from '@hooks/useWindowSize';
import Button from '@base/Button/Button';
import { useEffect } from 'react';
import { segment } from '@actions/analytics';
import { Banner_Banner_Viewed } from '@constants/segment';
import { useRouter } from 'next/router';
import { BANNER_CLICKED } from '@cont/EducatorProfile/constants';
import useCompeteConfig from './useCompeteConfig';
import {
  COMPETE_IMAGES,
  COMPETE_LANDING_PAGE,
  COMPETE_STATES
} from './constants';

const Container = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  background: var(--color-i-violet-22);
  height: ${Spacings.SPACING_16B};
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaQuery.uptoMobile} {
    height: 150px;
    padding: ${Spacings.SPACING_4B};
  }
`;

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaQuery.aboveMobile} {
    width: 1136px;
    height: ${Spacings.SPACING_16B};
    margin: auto;
    position: relative;
    z-index: 1;
    flex-direction: row;
  }
`;

const LeftImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  ${mediaQuery.uptoMobile} {
    top: 25px;
    left: -35px;
  }
`;

const RightImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  ${mediaQuery.uptoMobile} {
    top: 25px;
    right: -35px;
  }
`;

const Header = styled.div`
  font-weight: 600;
  font-size: ${Fonts.H5};
  line-height: 24px;
  color: var(--color-i-white);
  margin: 0 ${Spacings.SPACING_6B};
  display: flex;
  align-items: center;

  ${mediaQuery.uptoMobile} {
    font-size: ${Fonts.H7};
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 12px;
  }
`;

const StyledBtn = styled(Button)`
  background-color: var(--color-i-white);
  color: var(--color-i-icon-dark-tertiary);
  min-width: 97px;
  height: ${Spacings.SPACING_8B};
  min-height: ${Spacings.SPACING_8B};
  padding: ${Spacings.SPACING_4B};
  font-size: ${Fonts.H7};
  line-height: 16px;
  width: fit-content;

  &:hover {
    box-shadow: 0px 6px 20px var(--color-i-black-8);
  }

  &:active {
    box-shadow: 0px 6px 20px var(--color-i-black-8);
    background: var(--color-i-gray-29);
  }

  ${mediaQuery.uptoMobile} {
    min-width: 102px;
    padding: ${Spacings.SPACING_3B};
  }
`;

const sendEventOnClick = (ctaLabel, goalUID) => {
  segment.track(BANNER_CLICKED, {
    cta_button: ctaLabel,
    banner_title: 'Post Launch',
    goal_uid: goalUID,
    banner_type: 'Compete Awareness'
  });
};

const CompeteHeaderStrip = () => {
  const { isMobile } = useWindowSize();
  const { isCompete, state } = useCompeteConfig();
  const {
    query: { goalUID }
  } = useRouter();

  const isPostLaunch = state === COMPETE_STATES.POST_LAUNCH;

  useEffect(() => {
    if (isCompete && isPostLaunch) {
      segment.track(Banner_Banner_Viewed, {
        banner_title: 'Post Launch',
        banner_type: 'Compete Awareness',
        goal_uid: goalUID
      });
    }
  }, [goalUID, isCompete, isPostLaunch]);

  if (!isCompete || !isPostLaunch) {
    return null;
  }

  const handleCTA = () => {
    sendEventOnClick('Check it out', goalUID);
    window.open(COMPETE_LANDING_PAGE);
  };

  return (
    <Container>
      <ContentDiv>
        <LeftImage>
          <Image
            src={
              isMobile
                ? '/compete/icon-left.svg'
                : '/compete/post-launch-left.svg'
            }
            width={isMobile ? 105 : 224}
            height={isMobile ? 100 : 64}
            alt="clip-left"
          />
        </LeftImage>
        <RightImage>
          <Image
            src={
              isMobile
                ? '/compete/icon-right.svg'
                : '/compete/post-launch-right.svg'
            }
            width={isMobile ? 105 : 224}
            height={isMobile ? 100 : 64}
            alt="clip-right"
          />
        </RightImage>
        <Image
          src={COMPETE_IMAGES.LOGO_NO_RINGS}
          width={isMobile ? 150 : 140}
          height={isMobile ? 58 : 64}
          alt="icon"
          objectFit={isMobile ? 'cover' : 'contain'}
        />
        <Header>Battle and know where you stand</Header>
        <StyledBtn label="Check it out" onClick={handleCTA} />
      </ContentDiv>
    </Container>
  );
};

export default CompeteHeaderStrip;
