export const BATCH_GROUP = 'batch_group';
export const FREE = 'free';
export const BATCH = 'batch';
export const BATCH_GROUPS = 'batch_groups';
export const TOPICS = 'topics';
export const DPPS = 'educator_dpp';

// Events Constants
export const BANNER_CLICKED = 'Banner - Banner Clicked';
export const TOPICS_TAB_CLICKED = 'Educator - Profile Tab Clicked';
export const EDUCATOR_PROFILE_VIEWED = 'Educator Profile Viewed';
export const PRACTICE_STARTED = 'Practice - Practice Started';

export const stripLength = {
  goal: 11,
  topic: 13
};

export const MIN_QUIZ_ATTEMPTS = 10;

export const PARTNER_EDUCATOR_CONFIG = {
  color: {
    primary: '#6496BC'
  },
  asset: {
    badge: 'https://static.uacdn.net/web-cms/Partner_badge_2_4a9f6a1b22.png'
  },
  name: 'Partner Educator'
};

export const EX_EDUCATOR_CONFIG = {
  color: {
    primary: '#3C4852'
  },
  name: 'Ex-Educator'
};

// tab indexes
export const BATCH_TAB_INDEX = 0;
export const COURSE_TAB_INDEX = 1;
export const FREE_TAB_INDEX = 2;
export const ABOUT_TAB_INDEX = 3;

// header bg images
export const backGround = {
  expert: {
    web: '/static/images/educator/educatorLevelBg/expert.svg',
    mWeb: '/static/images/educator/educatorLevelBg/expertMweb.svg'
  },
  legend: {
    web: '/static/images/educator/educatorLevelBg/legend.svg',
    mWeb: '/static/images/educator/educatorLevelBg/legendMweb.svg'
  },
  star: {
    web: '/static/images/educator/educatorLevelBg/star.svg',
    mWeb: '/static/images/educator/educatorLevelBg/starMweb.svg'
  },
  risingstar: {
    web: '/static/images/educator/educatorLevelBg/risingStar.svg',
    mWeb: '/static/images/educator/educatorLevelBg/risingStarMweb.svg'
  },
  master: {
    web: '/static/images/educator/educatorLevelBg/master.svg',
    mWeb: '/static/images/educator/educatorLevelBg/masterMweb.svg'
  },
  partner: {
    web: '/static/images/educator/educatorLevelBg/partner.svg',
    mWeb: '/static/images/educator/educatorLevelBg/partnerMweb.svg'
  },
  exEducator: {
    web: '/static/images/educator/educatorLevelBg/exEducator.svg',
    mWeb: '/static/images/educator/educatorLevelBg/exEducatorMweb.svg'
  },
  partnereducator: {
    web: '/static/images/educator/educatorLevelBg/partner.svg',
    mWeb: '/static/images/educator/educatorLevelBg/partnerMweb.svg'
  }
};

// card types
export const SpecialClass = 'special_class';
export const Batches = 'batches';
export const PlusGoal = 'plus_goal';
export const FreeCourses = 'free_courses';
export const EducatorDpp = 'educator_dpp';
export const TabWrapperID = 'educator-tabs-wrapper';
