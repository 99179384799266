import styled from '@emotion/styled';
import { useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Logo from '@comp/Logo/Logo';
import Divider from '@base/Divider/Divider';
import useTimeout from '@hooks/useTimeout';
import Image from '@base/Image';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import Dynamic from '@base/Dynamic/Dynamic';
import { Button } from '@material-ui/core';
import useEducatorContext from '@hooks/useEducatorContext';
import { useFlagSelector } from '@hooks/useLayoutOptions';
import { mediaQuery, Spacings } from '@styles/index';
import { getIsLoggedIn } from '@api/selectors/auth';
import useWindowSize from '@hooks/useWindowSize';
import usePlatformInfo from '@hooks/usePlatformInfo';
import { getIsGTPGoal } from '@api/selectors/constants';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import { useSelectedGoalUID } from '@api/hooks/topology/useSelectedGoal';
import useConstants from '@api/hooks/useConstants';
import CompeteHeaderStrip from '@cont/Compete/CompeteHeaderStrip';
import useCompeteConfig from '@cont/Compete/useCompeteConfig';
import { COMPETE_STATES } from '@cont/Compete/constants';
import pushRoute from '@utils/pushRoute';
import { segment } from '@actions/analytics';
import {
  REFERRAL_TOPBAR_TOUCHPOINT,
  REFERRAL_TOUCHPOINT_CLICKED
} from '@constants/segment';
import HeaderContent from '../Components/HeaderParent';
import HeaderTop from '../Components/HeaderContainer';
import RenderLoginAction from './RenderLoginAction';

const GoalSelector = Dynamic(() => import('./GoalSelector/HeaderGoalSelector'));
const SearchTab = Dynamic(() => import('./SearchTab'));
const HeaderUser = Dynamic(() => import('./HeaderUser/HeaderUser'));
const ProfileOptions = Dynamic(() =>
  import('./HeaderUser/ProfileOptions/ProfileOptions')
);
const PaymentNudge = Dynamic(() => import('../PaymentLayout/PaymentNudge'));
const HeaderNotification = Dynamic(() => import('./HeaderNotification'));
const AutoDppDismissibleCard = Dynamic(() =>
  import('@educator/Composite/DashBoard/AutoDppDismissibleCard')
);
const NotificationToPwa = Dynamic(() => import('@layout/NotificationToPwa'));

const HeaderContainer = styled(HeaderTop)`
  background: ${({ transparent, hideBgColor }) =>
    transparent || hideBgColor ? 'none' : 'var(--color-base-1)'};
  box-shadow: ${({ transparent, hideBgColor, hideBoxShadow }) =>
    transparent || hideBgColor || hideBoxShadow
      ? 'initial'
      : boxShadowGenerator()};
  position: ${({ transparent }) => (transparent ? 'absolute' : 'sticky')};
  height: ${({ $isCompetePostLaunch }) =>
    $isCompetePostLaunch ? '136px' : '72px'};
  ${mediaQuery.uptoTablet} {
    height: ${({ $isCompetePostLaunch }) =>
      $isCompetePostLaunch ? '136px' : '72px'};
  }
  ${mediaQuery.uptoMobile} {
    box-shadow: inset 0px -1px 0px var(--color-shadow);
    display: block;
    width: 100%;
    height: ${({ $isCompetePostLaunch }) =>
      $isCompetePostLaunch ? '198px' : '48px'};
    background-color: var(
      ${({ $showCompeteBackground }) =>
        $showCompeteBackground ? '--color-i-violet-22' : '--color-base-1'}
    );
  }

  background-color: var(
    ${({ showCompeteBackground }) =>
      showCompeteBackground ? '--color-i-violet-22' : ''}
  );
`;

const HeaderParent = styled(HeaderContent)`
  height: 72px;

  ${mediaQuery.uptoTablet} {
    width: 100%;
    padding: 0 16px;
  }
  ${mediaQuery.uptoMobile} {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 16px;
    justify-content: space-between;
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 0 ${Spacings.SPACING_6B};
    height: 24px;
    width: 2px;
    border-radius: ${Spacings.SPACING_2};
  }
  ${mediaQuery.uptoMobile} {
    display: none;
  }

  background-color: var(
    ${({ $showCompeteBackground }) =>
      $showCompeteBackground ? '--color-i-divider' : ''}
  );
`;

const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${mediaQuery.uptoMobile} {
    display: none;
  }
`;

const HeaderReferralWrapper = styled(Button)`
  box-sizing: border-box;
  margin-right: ${({ $isLoggedIn }) =>
    $isLoggedIn ? Spacings.SPACING_0B : Spacings.SPACING_4B};
  padding: ${Spacings.SPACING_1B} ${Spacings.SPACING_3B};
  height: ${({ $isLoggedIn }) =>
    $isLoggedIn ? Spacings.SPACING_8B : Spacings.SPACING_10B};
  border: 1px solid var(--color-i-divider);
  border-radius: ${({ $isLoggedIn }) =>
    $isLoggedIn ? Spacings.SPACING_12B : Spacings.SPACING_1B};
  &.MuiButton-root {
    text-transform: unset;
    border-radius: 50%;
    ${({ $isLoggedIn }) => {
      return $isLoggedIn
        ? `min-width:${Spacings.SPACING_8B}; width:${Spacings.SPACING_8B}; height:${Spacings.SPACING_8B};`
        : `min-width:${Spacings.SPACING_10B}; width:${Spacings.SPACING_10B}; height:${Spacings.SPACING_10B};`;
    }}
  }
  ${mediaQuery.uptoMobile} {
    margin-right: ${({ $isLoggedIn }) =>
      $isLoggedIn ? Spacings.SPACING_0B : Spacings.SPACING_2B};
    &.MuiButton-root {
      min-width: 32px;
      height: 32px;
      width: 32px;
    }
  }
`;

const ReferralContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacings.SPACING_1B};
  flex: none;
`;

const HeaderUserContainer = styled.div`
  min-width: ${({ $hideWatchMinForPlatform }) =>
    $hideWatchMinForPlatform ? 'unset' : '185px'};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const GoalSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mediaQuery.uptoMobile} {
    flex: 1;
    margin-right: ${Spacings.SPACING_2B};
    button {
      padding-left: 0px;
    }
  }
`;
const LogoContainer = styled.div`
  ${mediaQuery.uptoMobile} {
    margin-right: ${Spacings.SPACING_3B};
  }
`;

const isSubscriptionPricingsUrl = (route) =>
  route === '/goal/[goalSlug]/[goalUID]/subscribe';

const onlyLogo = ['/careers/public-notice'];

const hideLoginButtonOnMobileUrls = [
  '/goal/[goalSlug]/[goalUID]',
  '/course/[slug]/[courseUID]',
  '/lesson/[lessonSlug]/[lessonUID]'
];

const Header = ({
  transparent,
  hideSearch,
  hideGoalSelector,
  isCheckoutUrl,
  isLoginUrl
}) => {
  const isEducatorSide = useEducatorContext();
  const router = useRouter();
  const { route } = router;
  const isNotificationsRoute = route === '/notifications';
  const [mounted, setMounted] = useState(false);
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const selectedGoalUID = useSelectedGoalUID();
  const showOnlyLogo = onlyLogo.includes(route);
  const { isMobile } = useWindowSize();
  useTimeout(() => setMounted(true), 0);

  let hideGoalSelectorInHeader = useFlagSelector('hideGoalSelector');
  hideGoalSelectorInHeader = hideGoalSelector || hideGoalSelectorInHeader;
  const { isPlatform, hideWatchMinForPlatform } = usePlatformInfo();

  const isProdigyUrl =
    route === '/prodigy' ||
    route === '/event/UCL' ||
    route === '/event/testseries/aspire' ||
    route === '/event/testseries/[testSeriesKey]';

  const isGoalOnBoardingPreferenceUrl =
    route === '/goal/[goalSlug]/[goalUID]/preferences';

  const isGTP = useConstants(getIsGTPGoal(selectedGoalUID));

  const displayNotification =
    isLoggedIn &&
    mounted &&
    !isNotificationsRoute &&
    !isEducatorSide &&
    !isCheckoutUrl &&
    !isProdigyUrl &&
    !isGoalOnBoardingPreferenceUrl &&
    !isGTP;

  const displayGoalSelector =
    !isCheckoutUrl &&
    !hideGoalSelectorInHeader &&
    !isEducatorSide &&
    !(isMobile && isLoginUrl) &&
    !isGoalOnBoardingPreferenceUrl;

  const displaySearch =
    !isPlatform &&
    !hideSearch &&
    !isCheckoutUrl &&
    !isEducatorSide &&
    mounted &&
    !(isMobile && isLoginUrl) &&
    !isGoalOnBoardingPreferenceUrl &&
    !isGTP;

  const { showCompeteBackground, state } = useCompeteConfig();

  const isCompetePostLaunch = state === COMPETE_STATES.POST_LAUNCH;
  const handleReferralRedirect = () => {
    segment.track(REFERRAL_TOUCHPOINT_CLICKED, {
      last_primary_source_section: REFERRAL_TOPBAR_TOUCHPOINT
    });
    const path = isLoggedIn ? '/settings/referral' : '/referral';
    pushRoute(path, path);
  };
  const isMWUrl = route === '/login' || route === '/about';
  if (isMobile && isMWUrl) {
    return (
      <HeaderContainer transparent={transparent}>
        <HeaderParent>
          <Logo isReload />
        </HeaderParent>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer
      transparent={transparent}
      hideBoxShadow={isGoalOnBoardingPreferenceUrl}
      $showCompeteBackground={showCompeteBackground}
      $isCompetePostLaunch={isCompetePostLaunch}
    >
      <CompeteHeaderStrip />
      <HeaderParent>
        <LogoContainer>
          {isCheckoutUrl && !isSubscriptionPricingsUrl(route) ? (
            <Logo isClassroom={route.includes('classroom')} disabled />
          ) : (
            <Logo
              isClassroom={route.includes('classroom')}
              isReload={(isProdigyUrl || isLoginUrl) && isMobile}
            />
          )}
        </LogoContainer>
        {!showOnlyLogo && (
          <>
            {displayGoalSelector && (
              <GoalSelectorWrapper>
                <StyledDivider
                  orientation="vertical"
                  $showCompeteBackground={showCompeteBackground}
                />
                <GoalSelector />
              </GoalSelectorWrapper>
            )}
            {isLoggedIn && isMobile && (
              <ProfileOptions excludeOptions={['darkMode']} />
            )}
            <RightContentWrapper>
              {displaySearch && <SearchTab />}
              {displayNotification && <HeaderNotification />}
              <PaymentNudge />
              <NotificationToPwa />
              {isEducatorSide && <AutoDppDismissibleCard />}
              {!isCheckoutUrl && !isLoginUrl && (
                <>
                  <HeaderReferralWrapper
                    onClick={handleReferralRedirect}
                    $isLoggedIn={isLoggedIn}
                  >
                    <ReferralContainer>
                      <Image
                        src="giftHomePage.svg"
                        height="18px"
                        width="18px"
                        alt="avatar"
                        loading="eager"
                        priority
                      />
                    </ReferralContainer>
                  </HeaderReferralWrapper>
                  {isLoggedIn ? (
                    <HeaderUserContainer
                      $hideWatchMinForPlatform={hideWatchMinForPlatform}
                    >
                      <HeaderUser
                        isEducatorUrl={isEducatorSide}
                        isHeaderTransparent={transparent}
                      />
                    </HeaderUserContainer>
                  ) : (
                    <RenderLoginAction isJoinForFreeCtaExperiment />
                  )}
                </>
              )}
            </RightContentWrapper>

            {isMobile && !isLoggedIn && !isCheckoutUrl && !isLoginUrl && (
              <RenderLoginAction
                show={!hideLoginButtonOnMobileUrls.includes(route)}
                isJoinForFreeCtaExperiment
              />
            )}
          </>
        )}
      </HeaderParent>
    </HeaderContainer>
  );
};

export default Header;

Header.propTypes = {
  isCheckoutUrl: PropTypes.bool,
  hideSearch: PropTypes.bool,
  hideGoalSelector: PropTypes.bool,
  transparent: PropTypes.bool,
  isLoginUrl: PropTypes.bool
};

Header.defaultProps = {
  isCheckoutUrl: false,
  hideSearch: false,
  hideGoalSelector: false,
  transparent: false,
  isLoginUrl: false
};
